<template>
  <div class="pa-9">
    <ValidationObserver ref="add_book">
      <h4>Tên sách</h4>
      <ValidationProvider
          name="title"
          rules="required"
          v-slot="{ errors }"
      >
        <HnrInput
            :name="'title'"
            v-model="book.title"
            :label="'Nhập title sách'"
        />
        <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
      </ValidationProvider>
      <h4>Giá sách</h4>
      <v-file-input
          class="pl-1"
          id="file_input"
          prepend-icon="mdi-camera"
          v-model="image"
          label=""
          @change="onChangeFileUpload"
          @click:clear="clearImage"
      ></v-file-input>
      <div>
        <img v-if="book.url" :src="book.url" width="200px"/>
      </div>
      <span class="validate-error text-danger">{{ imageError }}</span>
      <h4>Giá sách</h4>
      <ValidationProvider name="price" rules="required|number" v-slot="{ errors }">
        <vuetify-money
            v-model="book.price"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
        />
        <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
      </ValidationProvider>
      <v-checkbox v-model="book.sale" class="mx-2" label="Giảm giá sách"></v-checkbox>
      <h4>Giá sách</h4>
      <vuetify-money
          v-model="book.amount"
          v-bind:label="label"
          v-bind:placeholder="placeholder"
          v-bind:readonly="readonly"
          v-bind:disabled="disabled"
          v-bind:outlined="outlined"
          v-bind:clearable="clearable"
          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
          v-bind:options="options"
      />
      <h4>Mô tả</h4>
      <CkContent
          :content="book.description"
          @getData="(value) => book.description = value"
      ></CkContent>
    </ValidationObserver>
    <v-row class="d-flex align-center justify-center my-9">
      <v-col cols="4">
        <HnrButton color="primary" @hnrClickEvent="saveConfig" :text="save"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {UPLOAD_BOOK_IMAGE,UPDATE_BOOK,GET_DETAIL_BOOK} from "@/store/book.module";
import {mapGetters} from "vuex";

export default {
  components: {
    CkContent: () => import( "@/components/ck-content"),
    HnrInput: () => import("@/components/elements/hnr-input"),
    HnrButton: () => import("@/components/elements/hnr-button")
  },
  data() {
    return {
      book: {},
      imageError: "",
      image: {},
      label: "",
      placeholder: "Nhập số tiền khóa học",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
      options: {
        locale: "pt-BR",
        prefix: "VNĐ$",
        suffix: "",
        length: 11,
        precision: 0,
      },
      save : 'Lưu'

    }
  },
  computed: {
    ...mapGetters({
      bookDetail: "bookDetail",
    }),
  },
  watch:{
    bookDetail(newVal){
      this.book = Object.assign({},newVal)
    }
  },
  created() {
    this.$store.dispatch(GET_DETAIL_BOOK, this.$route.params.id)
  },
  methods: {
    onChangeFileUpload() {
      if (this.image) {
        this.imageError = "";
        let formData = new FormData();
        formData.append("upload", this.image);
        this.$store
            .dispatch(UPLOAD_BOOK_IMAGE, formData)
            .then((data) => {
              if (data.data.status == 1) {
                this.book.url = data.data.data;
                this.$forceUpdate()
              }
            })
      }
    },
    clearImage() {
      this.url = "";
      this.book.url = "";
    },
    async saveConfig(){
      let result = await this.$refs.add_book.validate()
      if (result) {
        this.$store
            .dispatch(UPDATE_BOOK, this.book)
            .then((data) => {
              if (data.data.status == 1) {
                this.$toasted.success('Sửa thành công', {
                  position: "top-right",
                  theme: "toasted-primary",
                  duration: 1000
                });
                this.$router.push('/book/list')
              }
            })
      }
      else {
        this.$toasted.error('Bạn chưa nhập đủ thông tin', {
          position: "top-right",
          theme: "toasted-primary",
          duration: 1000
        });
      }
    }
  }
}
</script>